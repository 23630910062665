import React, {useState} from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";

const SearchInput = ({ onSearch }) => {
    const [searchTags, setSearchTags] = useState([])

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTags(value);
        onSearch(value)
    };

    
    return (
            <div className="search-container">
                <label htmlFor="search-input">
                   <FaMagnifyingGlass id="icon-glass"/>
                </label>
                <input
                    className="search-line"
                    type="text"
                    id="search-input"
                    value={searchTags}
                    placeholder="Enter some information"
                    onInput={handleInputChange}/>
            </div>
    )
}

export default SearchInput