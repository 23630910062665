import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Header from "../components/Header";
import Users from '../adminPanelPages/Users';
import Promocodes from '../adminPanelPages/Promocodes';
import CustomerLogs from '../adminPanelPages/Logs/CustomerLogs';
import PurchaseLogs from '../adminPanelPages/Logs/PurchaseLogs';
import StaffLogs from '../adminPanelPages/Logs/StaffLogs';
import Statistics from '../adminPanelPages/Statistics';
import ErrorBlock from '../components/ErrorBlock';

import { FaUsers } from "react-icons/fa";
import { IoTicketSharp } from "react-icons/io5";
import { BsPeopleFill } from "react-icons/bs";
import { FaShoppingBasket } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { IoStatsChart } from "react-icons/io5";
import { IoIosStats } from "react-icons/io";

function AdminPanel() {
    const [username, setUsername] = useState("");
    const [userRole, setUserRole] = useState("");
    const [currentComponent, setCurrentComponent] = useState('Users');

    const navigate = useNavigate();
    axios.defaults.withCredentials = true;

    useEffect(() => {
        const authenticateUser = async () => {
            try {
                const response = await axios.post("http://apiwenose.slovo.kiev.ua/protected-route", {}, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                
                if (response.data.status === "Success") {
                    const { role, username } = response.data.userData;
                    if (role === "Guest" || role === "Customer") {
                        navigate('/');
                    } else {
                        setUsername(username);
                        setUserRole(role);
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error("Authentication failed:", error);
                navigate('/');
            }
        };

        authenticateUser();
    }, []);

    const handleComponentChange = (component) => {
        setCurrentComponent(component);
    };

    return (
        <div className="main-container">
            <Header />
            <section className="admin-panel-container">
                <section>
                    <section className="admin-person-block">
                        <span>{username}</span>
                        <div className="admin-person-role">
                            <span>{userRole}</span>
                        </div>
                    </section>
                    <section className="admin-panel-toolbar">

                        <div className="admin-panel-bar">
                            <h2>Management</h2>
                            <button onClick={() => handleComponentChange('Users')}>
                                <FaUsers />
                                Users
                            </button>
                            <button onClick={() => handleComponentChange('Promocodes')}>
                                <IoTicketSharp />
                                Promocodes
                            </button>
                        </div>

                        <div className="admin-panel-bar">
                            <h2>Logs</h2>
                            <button onClick={() => handleComponentChange('Customer-logs')}>
                                <BsPeopleFill />
                                Customers
                            </button>
                            {userRole === "Admin" && (
                                <>
                                    <button onClick={() => handleComponentChange('Purchase-logs')}>
                                        <FaShoppingBasket />
                                        Purchases
                                    </button>
                                    <button onClick={() => handleComponentChange('Staff-logs')}>
                                        <MdAdminPanelSettings />
                                        Staff
                                    </button>
                                </>
                            )}
                        </div>

                        {userRole === "Admin" && (
                            <div className="admin-panel-bar">
                                <h2>Finance</h2>
                                <button onClick={() => handleComponentChange('Statistics')}>
                                    <IoIosStats />
                                    Statistics
                                </button>
                            </div>
                        )}
                    </section>
                </section>
                
                {currentComponent === "Users" && <Users userRole={userRole} />}
                {currentComponent === "Promocodes" && <Promocodes userRole={userRole} />}
                {currentComponent === "Customer-logs" && <CustomerLogs />}
                {currentComponent === "Purchase-logs" && <PurchaseLogs />}
                {currentComponent === "Staff-logs" && <StaffLogs />}
                {currentComponent === "Statistics" && <Statistics />}
            </section>
        </div>
    );
}

export default AdminPanel;
