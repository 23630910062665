import { React, useEffect } from "react"
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

import { 
    MdContactSupport, MdOutlineLogin, MdOutlineViewInAr, MdAdminPanelSettings 
} from "react-icons/md"
import { 
    FaShopify, FaBars 
} from "react-icons/fa"
import { FaCircleUser } from "react-icons/fa6";

import logo from "../images/avatarka.png"

const Header = () => {
    let auth = localStorage.getItem("auth") || 0

    axios.defaults.withCredentials = true
    useEffect(() => {
        axios.post("http://apiwenose.slovo.kiev.ua/protected-route", {}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                'Refresh-Token': Cookies.get('Refresh_token')
            }
        }).then((response) => {
            if (response.data.status === "Success") {
                if (response.data.access_token) {
                    Cookies.set('Access_token', response.data.access_token, { expires: 0.00347222 })
                }
                if (response.data.userData.role === "Guest") {
                    localStorage.setItem("auth", 1);
                } else if (response.data.userData.role === "Customer") {
                    localStorage.setItem("auth", 2);
                } else if (response.data.userData.role === "Moderator") {
                    localStorage.setItem("auth", 3);
                } else if (response.data.userData.role === "Admin") {
                    localStorage.setItem("auth", 4);
                }
            } else {
                localStorage.setItem("auth", 0)
            }
        })
    }, [])
    return (
        <header>
            <nav>
                <Link className="logo-header link" to={"/"}>
                    <img src={logo}/>
                    <span>WENOSE</span> 

                </Link>
                <div className="header-block">
                    <Link className="link" to={"/"}>
                        <MdContactSupport />
                        Support
                    </Link>
                    <Link className="link" to={"/market"}>
                        <FaShopify />
                        Market
                    </Link>
                    
                    { 
                        auth == 1 || auth == 2 ?
                        <Link className="link" to={"/cabinet"}>
                            <FaCircleUser />
                            Cabinet
                        </Link>
                        : ( auth == 3 || auth == 4 ?
                            <div style={{display: "inline-flex", gap: "1.5rem"}}>
                                <Link className="link" to={"/admin-panel"}>
                                    <MdAdminPanelSettings style={{fontSize: "1.5rem"}}/>
                                    Admin panel
                                </Link> 
                                <Link className="link" to={"/cabinet"}>
                                    <FaCircleUser />
                                    Cabinet
                                </Link>
                            </div>
                    
                            :
                            <Link className="link" to={"/login"}>
                                <MdOutlineLogin />
                                Join us
                            </Link> 

                        )


                    }
                </div>
                <div className="header-navigation-bar">
                    <FaBars />
                </div>
            </nav>

        </header>
    )
}
export default Header