import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/Header";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Cabinet from "./pages/Cabinet";
import Market from "./pages/Market";
import AdminPanel from "./pages/AdminPanel";

function App() {
    return (
        <BrowserRouter>
            
            <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/login' element={<Login />}></Route>
                <Route path='/register' element={<Register />}></Route>
                <Route path='/cabinet' element={<Cabinet />}></Route> 
                <Route path='/market' element={<Market />}></Route>
                <Route path="/admin-panel" element={<AdminPanel />}></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;