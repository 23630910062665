import React, { Suspense, lazy } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Spinner from "../components/Spinner";

import ava1 from "../images/30_days.png";
import ava2 from "../images/lifetime.png";
import ava3 from "../images/90_days.png";
import ava4 from "../images/Reset_HWID.png";
import ava5 from "../images/Premium.png";

const products = [
    { image: ava1, title: "Subscription for 30 days", price: 199 },
    { image: ava2, title: "Subscription lifetime", price: 599 },
    { image: ava3, title: "Subscription for 90 days", price: 349 },
    { image: ava4, title: "Reset HWID", price: 199 },
    { image: ava5, title: "Premium lifetime", price: 249 },
];

const MarketBlock = lazy(() => import("../components/MarketBlock"));

function Market() {
    return (
        <div>
            <Header />
            <div className="main-container">
                <Suspense fallback={<Spinner />}>
                    <div className="market-container">
                        {products.map((product, index) => (
                            <MarketBlock
                                key={index}
                                image={product.image}
                                title={product.title}
                                price={product.price}
                            />
                        ))}
                    </div>
                </Suspense>
            </div>
            <Footer />
        </div>
    );
}

export default Market;
