import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./css/main.css"
import "./css/Home.css"
import "./css/Login.css"
import "./css/Register.css"
import "./css/Cabinet.css"
import "./css/media-requests.css"
import "./css/Market.css"
import "./css/Review.css"
import "./css/AdminPanel.css"
import "./css/AdminPanelToolBar.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)