function UserInfoBlock({ icon, label, value, button }) {
    return (
        <div className="cabinet-block">
            <div className="cabinet-block-title">
                {icon}
                <span>{label}</span>
            </div>
            <div className="cabinet-block-info">
                <span>{value}</span>
                {button}
            </div>
        </div>
    );
}
export default UserInfoBlock