import React from "react";
import { FaCircleCheck } from "react-icons/fa6";

const SuccessBlock = ({ successMessage, handleModalClose }) => {
    return (
        // <div className="success-block-container">
        //     <div className="success-block-header">
        //         <div className="success-block-title">
        //             <FaCircleCheck />
        //             <strong>Success</strong>                    
        //         </div>
        //         <span className="close-icon" onClick={handleModalClose}>
        //             &times;
        //         </span>
        //     </div>
        //     <div className="success-block-content">
        //         <div>{successMessage}</div>
        //     </div>
        // </div>
        <div className="success-block-container" onClick={handleModalClose}>
            <div className="success-block-header">
                <div className="success-block-title">
                    <FaCircleCheck />
                    <strong>Success &gt;&gt;</strong>    
                    {successMessage}                
                </div>
            </div>
        </div>    
    )
}
export default SuccessBlock