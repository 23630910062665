import { FaDiscord, FaTelegram, FaVk } from "react-icons/fa";

const Footer = () => {
    const handleRedirect = (url) => {
        switch (url) {
            case "discord":
                window.open('https://discord.gg/GKJ7yfnfMW', '_blank');
                break;
            case "telegram":
                window.open('https://web.telegram.org', '_blank');
                break;
            case "vk":
                window.open('https://vk.com', '_blank');
                break;
            default:
                console.error(`Unknown URL: ${url}`);
        }
    };

    return (
        <footer>
            <span>© Wenose client 2023-2024 </span>
            <FaDiscord onClick={() => handleRedirect("discord")}/>
            <FaTelegram onClick={() => handleRedirect("telegram")}/>
            <FaVk onClick={() => handleRedirect("vk")}/>
        </footer>
    )
}
export default Footer