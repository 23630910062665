import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Cookies from "js-cookie";

import ErrorBlock from "../components/ErrorBlock";

function Login() {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
    };
    const [values, setValues] = useState({
        username: '',
        password: '',
        ip: '',
        checked: false,
        errors: {}
    });

    axios.defaults.withCredentials = true
    useEffect(() => {
        axios.post("http://apiwenose.slovo.kiev.ua/protected-route", {}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                'Refresh-Token': Cookies.get('Refresh_token')
            }
        }).then((response) => {
            if (response.data.status === "Success" ) {
                navigate('/')
            }
        })
        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setValues((prev) => ({ ...prev, ip: data.ip }));
            } catch (error) {
                console.error('Problem fetching IP', error);
            }
        };
        fetchIP()
    }, [])

    const handleChange = () => {
        setValues(prev => ({ ...prev, checked: !prev.checked }));
    };  
    
    const handleInput = (event) => {
        setValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        const errors = validate(values);
        if (Object.keys(errors).length > 0) {
          setValues((prev) => ({ ...prev, errors }));
          setShowModal(true)
          return;
        }

        try {
            const response = await axios.post('http://apiwenose.slovo.kiev.ua/login', values);
            
            if (response.data.status === 'Success') {
                const { access_token, refresh_token, userRole } = response.data;

                const accessTokenExpiry = 0.00347222;
                const refreshTokenExpiry = values.checked ? 60 : 1;

                Cookies.set('Access_token', access_token, { expires: accessTokenExpiry });
                Cookies.set('Refresh_token', refresh_token, { expires: refreshTokenExpiry });

                const authLevel = userRole === "Guest" ? 1 : userRole === "Member" ? 2 : userRole === "Moderator" ? 3 : userRole === "Admin" ? 4 : 0;
                localStorage.setItem("auth", authLevel);

                navigate('/');
            } else {
                setValues(prev => ({ ...prev, errors: { login: 'Login failed. Please check your credentials.' } }));
            }
        } catch (error) {
            console.error('Ошибка при авторизации:', error);
        }
    };

    const validate = (values) => {
        const errors = {};
    
        if (!values.username) {
          errors.username = "Username is required";
        } else if (values.username.length < 6 || values.username.length > 35) {
          errors.username = "Username must be at least 6 and no more than 35 characters";
        }
    
        if (!values.password) {
          errors.password = "Password is required";
        } else if (values.password.length < 6 || values.password.length > 65) {
          errors.password = "Password must be at least 6 and no more than 65 characters";
        }
    
        return errors;
    };

    return (
        <div>
        <Header />
        <div className="login-container">
            {(values.errors && showModal) && (
                <ErrorBlock errorMessage={"Check the form fields"} handleModalClose={handleModalClose}/>
            )}
            <form className="login-form" onSubmit={handleLogin}>
                <div className="form-title">
                    <h1>Sign In</h1>
                    <p>Don't have an account? <Link className="login-link" to={"/register"}>Sign Up</Link></p>
                </div>
                <hr className="h-line" />
                <div className="username-inp login-block">
                    <label htmlFor="username">
                        <span>Username</span>
                        <span className="required-sp">REQUIRED</span>
                    </label>
                    <input 
                        type="text"
                        value={values.username}
                        onChange={handleInput} 
                        placeholder="Username" 
                        autoComplete="given-name"
                        name="username" 
                        id="username" 
                    />
                </div>
                <div className="password-inp login-block">
                    <label htmlFor="password">
                        <span>Password</span>
                        <span className="required-sp">REQUIRED</span>
                    </label>
                    <input 
                        type="password" 
                        value={values.password}
                        onChange={handleInput} 
                        placeholder="Password" 
                        autoComplete="off" 
                        name="password" 
                        id="password" 
                    />
                </div>
                <div className="remember-me-container">
                    <label className="check-box">
                        <input  type="checkbox" checked={values.checked} onChange={handleChange}/>
                        <div className="checkmark"></div>
                        Remember me
                    </label>
                </div>
                <button type="submit" className="sign-in-button">SIGN IN</button>
                <Link to={"/"} className="forgot-password">Forgot your password?</Link>
            </form>            
        </div>

        </div>
    )
}
export default Login