import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Cookies from "js-cookie";

import Footer from "../components/Footer";
import Header from "../components/Header";
import NavigationButton from "../components/NavigationButton";
import UserInfoBlock from "../components/UserInfoBlock";
import ErrorBlock from "../components/ErrorBlock";
import SuccessBlock from "../components/SuccessBlock";

import { FaFingerprint, FaUserCircle, FaLayerGroup, FaCalendarAlt,
        FaKey, FaShopify, FaDiscord, FaSignOutAlt
} from "react-icons/fa"
import { FaComputer, FaClockRotateLeft, 
    FaUnlockKeyhole, FaDownload 
} from "react-icons/fa6"
import { GrPowerReset, GrUpdate } from "react-icons/gr"
import { MdEmail } from "react-icons/md";

function Cabinet() {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [statusMessage, setStatusMessage] = useState({})
    const [keyValue, setKeyValue] = useState({key: ""})
    const [userData, setUserData] = useState({
        uid: "",
        login: "",
        email: "",
        role: "",
        hwid: "",
        registrationDate: "",
        buyedUntil: ""
    });

    useEffect(() => {
        axios.defaults.withCredentials = true        
        axios.post("http://apiwenose.slovo.kiev.ua/protected-route", {}, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                'Refresh-Token': Cookies.get('Refresh_token')
            }
        }).then((response) => {
            if (response.data.status === "Success") {
                const infoAboutUser = response.data.userData;
                setUserData({
                    uid: infoAboutUser.id,
                    login: infoAboutUser.username,
                    email: infoAboutUser.email,
                    registrationDate: infoAboutUser.registerDate,
                    role: infoAboutUser.role,
                    hwid: infoAboutUser.hwid,
                    buyedUntil: infoAboutUser.buyed_until
                });
            } else {
                navigate('/login')
            }
        })
    }, [])

    const handleInput = (event) => {
        setKeyValue((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleActivateKey = async (e) => {
        e.preventDefault();

        axios.post("http://apiwenose.slovo.kiev.ua/activate-key", {
            ...keyValue
        }, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                'Refresh-Token': Cookies.get('Refresh_token')
            }
        }).then((response) => {
            if (response.data.status === "Success") {
                setStatusMessage({success: "The key has been activated"})
            } else {
                setStatusMessage({error: "The key was not activated"})
            }

            setShowModal(true)
        })
    }
    const logout = () => {
        localStorage.setItem("auth", 0)
        Cookies.remove("Access_token")
        Cookies.remove("Refresh_token")
        navigate("/")
    };

    const { uid, login, email, role, hwid, registrationDate, buyedUntil } = userData;

    return (
        <div>
            <Header />
            <div className="personal-cabinet-main-container">
                <div className="cabinet-container">
                    {(statusMessage.error && showModal) && (
                        <ErrorBlock errorMessage={statusMessage.error} handleModalClose={handleModalClose}/>
                    )}
                    {(statusMessage.success && showModal) && (
                        <SuccessBlock successMessage={statusMessage.success} handleModalClose={handleModalClose}/>
                    )}
                    <h1 id="cabinet-title">Personal cabinet</h1>
                    
                    <UserInfoBlock icon={<FaFingerprint />} label="UID" value={uid} />
                    <UserInfoBlock icon={<FaUserCircle />} label="Username" value={login} />
                    <UserInfoBlock icon={<FaLayerGroup />} label="Role" value={role} />
                    <UserInfoBlock icon={<FaCalendarAlt />} label="Registration-date" value={registrationDate.slice(0, 10)} />
                    <UserInfoBlock icon={<MdEmail />} label="E-mail" value={email} />
                    <UserInfoBlock 
                        icon={<FaComputer />} 
                        label="HWID" 
                        value={hwid ?? "Unknown"} 
                        button={hwid && <button className="cabinet-block-info-btn" onClick={() => navigate('/market')}><GrPowerReset />Reset</button>} 
                    />
                    {buyedUntil && (
                        <UserInfoBlock 
                            icon={<FaClockRotateLeft />} 
                            label="Subscription left" 
                            value={buyedUntil.slice(0, 10)} 
                            button={<button className="cabinet-block-info-btn" onClick={() => navigate('/market')}><FaShopify />Extend</button>} 
                        />
                    )}
                    <div className="cabinet-block">
                        <div className="cabinet-block-title">
                            <FaKey />
                            <span>Key activation</span>
                        </div>
                        <form className="cabinet-block-info" onSubmit={handleActivateKey}>
                            <input 
                                placeholder="Enter a key" 
                                type="text" 
                                autoComplete="off" 
                                name="key" 
                                className="cabinet-block-info-input" 
                                value={keyValue.key} 
                                onChange={handleInput}
                            />
                            <button className="cabinet-block-info-btn" type="submit">
                                <FaUnlockKeyhole />Activate
                            </button>
                        </form>
                    </div>
                </div>
                <div className="cabinet-container-navigation-btns">
                    <NavigationButton 
                        condition={buyedUntil}
                        trueText="Download loader" 
                        falseText="Buy client" 
                        trueIcon={<FaDownload />} 
                        falseIcon={<FaShopify />} 
                        trueAction={() => {}} 
                        falseAction={() => navigate('/market')} 
                    />
                    <button>
                        <FaDiscord />Discord
                    </button>
                    <button>
                        <GrUpdate />Change password
                    </button>
                    <button className="cabinet-logout-btn" onClick={logout}>
                        <FaSignOutAlt />Logout
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Cabinet