import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import Cookies from "js-cookie";

import ErrorBlock from "../components/ErrorBlock";

function Register() {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [values, setValues] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        ip: '',
        errors: {}
    });

    axios.defaults.withCredentials = true;

    useEffect(() => {
        const fetchProtectedRoute = async () => {
            try {
                const response = await axios.post("http://apiwenose.slovo.kiev.ua/protected-route", {}, {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                if (response.data.status === "Success") {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error accessing protected route:', error);
            }
        };

        const fetchIP = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                setValues((prev) => ({ ...prev, ip: data.ip }));
            } catch (error) {
                console.error('Problem fetching IP', error);
            }
        };

        fetchProtectedRoute();
        fetchIP();
    }, []);

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleChange = () => {
        setChecked(!checked);
    };

    const handleInput = (event) => {
        setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        const errors = validate(values);
        if (Object.keys(errors).length > 0) {
            setValues((prev) => ({ ...prev, errors }));
            setShowModal(true)
            return;
        }

        if (checked && (values.password === values.confirmPassword)) {
            try {
                const response = await axios.post('http://apiwenose.slovo.kiev.ua/register', values);
                if (response.data.status === 'Success') {
                    navigate('/login');
                } else {
                    alert("Fail");
                }
            } catch (error) {
                console.error('Error during registration:', error);
            }
        }
    };

    const validate = (values) => {
        const errors = {};

        if (!values.username) {
            errors.username = "Username is required";
        } else if (values.username.length < 6 || values.username.length > 35) {
            errors.username = "Username must be at least 6 and no more than 35 characters";
        }

        if (!values.email) {
            errors.email = "Email is required";
        } else if (values.email.length < 5 || values.email.length > 50) {
            errors.email = "Email must be at least 5 and no more than 50 characters";
        }

        if (!values.password) {
            errors.password = "Password is required";
        } else if (values.password.length < 6 || values.password.length > 65) {
            errors.password = "Password must be at least 6 and no more than 65 characters";
        }

        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }

        return errors;
    };

    return (
        <div>
            <Header />
            <div className="register-container">
                {(values.errors && showModal) && (
                    <ErrorBlock errorMessage={"Check the form fields"} handleModalClose={handleModalClose}/>
                )}
                <form className="register-form" onSubmit={handleRegister}>
                    <div className="form-title">
                        <h1>Sign Up</h1>
                        <p>Don't have an account? <Link to={"/login"} className="register-link">Sign In</Link></p>
                    </div>
                    <hr className="h-line" />

                    <div className="username-inp register-block">
                        <label htmlFor="username">
                            <span>Display Name</span>
                            <span className="required-sp">REQUIRED</span>
                        </label>
                        <input
                            type="text"
                            value={values.username}
                            onChange={handleInput}
                            placeholder="Display Name"
                            autoComplete="off"
                            id="username"
                            name="username"
                        />
                    </div>

                    <div className="email-inp register-block">
                        <label htmlFor="email">
                            <span>Email Address</span>
                            <span className="required-sp">REQUIRED</span>
                        </label>
                        <input
                            type="email"
                            value={values.email}
                            onChange={handleInput}
                            placeholder="Email Address"
                            autoComplete="on"
                            id="email"
                            name="email"
                        />
                    </div>

                    <div className="password-inp register-block">
                        <label htmlFor="password">
                            <span>Password</span>
                            <span className="required-sp">REQUIRED</span>
                        </label>
                        <input
                            type="password"
                            value={values.password}
                            onChange={handleInput}
                            placeholder="Password"
                            autoComplete="off"
                            id="password"
                            name="password"
                        />
                    </div>

                    <div className="confirm-password-inp register-block">
                        <label htmlFor="confirmPassword">
                            <span>Confirm Password</span>
                            <span className="required-sp">REQUIRED</span>
                        </label>
                        <input
                            type="password"
                            value={values.confirmPassword}
                            onChange={handleInput}
                            placeholder="Confirm Password"
                            autoComplete="off"
                            id="confirmPassword"
                            name="confirmPassword"
                        />
                    </div>

                    <div className="captcha">
                        <span>Security Check</span>
                    </div>

                    <hr className="h-line" />

                    <div className="remember-me-container">
                        <label className="check-box">
                            <input type="checkbox" checked={checked} onChange={handleChange} />
                            <div className="checkmark"></div>
                            I agree to the Terms of Use <span className="required-sp">REQUIRED</span>
                        </label>
                    </div>

                    <hr className="h-line" />

                    <button className="sign-up-button" type="submit">CREATE MY ACCOUNT</button>
                </form>                
            </div>
        </div>
    );
}

export default Register;
