import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from 'react-router-dom';

import { FaCalendarAlt, FaDiscord,
    FaTelegramPlane 
} from "react-icons/fa"
import { FaUsers, FaList } from "react-icons/fa6"
import { MdElectricBolt, MdOutlineSecurity, MdUpdate,
    MdCyclone, MdOutlineViewInAr 
} from "react-icons/md"
import { FiExternalLink } from "react-icons/fi";
import { RiListSettingsLine } from "react-icons/ri";
import { HiRocketLaunch } from "react-icons/hi2";
import { IoLogoVk } from "react-icons/io5";

import gui from "../images/wenose-gui.png"

function Home() {
    const navigate = useNavigate();

    return (
        <div>
            <Header />
            <div className="main-container">
                <div className="home-container">

                    <div className="main-info">
                        <div className="main-info-block">
                            <h1>WENOSE</h1>
                            <div>
                                <p className="text-under-logo">Here may be many paths to victory,<br />
                                    but our product — is the shortest.</p>
                            </div>
                            <button onClick={() => navigate('/market')}>
                                Go to Market
                                <FiExternalLink style={{fontSize: "1.25rem"}}/>
                            </button>
                            <div className="links-container">
                                <div className="links-block">
                                    <FaDiscord className="icon-links"/>
                                    <b>.gg/Wenose_client</b>
                                </div>
                                <div className="links-block">
                                    <FaTelegramPlane className="icon-links"/>
                                    <b>@Wenose client</b>
                                </div>
                                <div className="links-block">
                                    <IoLogoVk className="icon-links"/>
                                    <b>vk.com/Wenose_client</b>
                                </div>
                            </div>
                        </div>
                        <div className="gui">
                            <img src={gui} />
                        </div>
                    </div>
                    <div className="our-advantages">
                        <span className="advantages-title">
                            <FaList className="icons-adv-b"/>
                            OUR ADVENTAGES
                        </span>
                        <div className="advantages-container-top">
                            <div className="advantages-block">
                                <span><MdElectricBolt className="icons-adv-b"/>Optimization</span>
                                <p>We have very good client optimization. Even on weak computers you will have a stable and enjoyable game. </p>
                            </div>
                            <div className="advantages-block">
                                <span><MdOutlineSecurity className="icons-adv-b"/>Security</span>
                                <p>We do not sell your data and encrypt it for the highest security. Our system is also protected from attackers.</p>
                                
                            </div>
                            <div className="advantages-block">
                                <span><MdUpdate className="icons-adv-b"/>Updates</span>
                                <p>Our client is very new and every time we add something new and unique here to make playing as comfortable as possible.</p>
                                
                            </div>
                        </div>
                        <div className="advantages-container-bottom">
                            <div className="advantages-block">
                                <span><RiListSettingsLine className="icons-adv-b"/>Bypasses</span>
                                <p>We have the best bypasses for all popular anti-cheats such as matrix and grim. We also have workarounds for custom anti-cheats on servers such as Funtime and HolyWorld.</p>
                            </div>
                            <div className="advantages-block">
                                <span><MdCyclone className="icons-adv-b"/>Visuals</span>
                                <p>We have a very beautiful visual component of the client and we will improve it at the request of our users. Already at the moment we have 20+ visual modules.</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="review-video-review">
                            <span>
                                <MdOutlineViewInAr className="icons-adv-b"/>
                                VIDEO REVIEW OF OUR CLIENT</span>
                            <iframe
                                src="https://www.youtube.com/embed/U1ugUBQUBw8"
                                title="Video review of our client" 
                                frameBorder="0"
                                allow="accelerometer; 
                                    autoplay; 
                                    clipboard-write; 
                                    encrypted-media; 
                                    gyroscope; 
                                    picture-in-picture" 
                                allowFullScreen
                            
                            ></iframe>
                    </div>
                    <div className="client-stats">
                        <div className="client-stats-block">
                            <FaUsers className="icons"/>
                            <div className="client-stats-text">
                                <b>Users of client</b>
                                <span>666</span>
                            </div>
                        </div>

                        <div className="client-stats-block">
                            <HiRocketLaunch className="icons"/>
                            <div className="client-stats-text">
                                <b>Client launches</b>
                                <span>666</span>
                            </div>
                        </div>

                        <div className="client-stats-block">
                            <MdUpdate className="icons"/>
                            <div className="client-stats-text">
                                <b>Updates are out</b>
                                <span>666</span>
                            </div>
                        </div>

                        <div className="client-stats-block">
                            <FaCalendarAlt className="icons"/>
                            <div className="client-stats-text">
                                <b>Days since opening</b>
                                <span>666</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}
export default Home