import React from "react";

function PurchaseLogs() {
    return (
        <div>
            <h1>Soon...</h1>
        </div>
    )
}

export default PurchaseLogs