import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";
import SearchInput from "../../adminPanelComponents/SearchInput";
import Cookies from "js-cookie";

import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import Spinner from "../../components/Spinner";

const RenderInformationForLogs = lazy(() => import('../../adminPanelComponents/RenderInformationForLogs'));

function CustomerLogs() {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [customerLogs, setCustomerLogs] = useState([]);
    const [searchingCustomerLogs, setSearchingCustomerLogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const logResponse = await axios.post(`http://apiwenose.slovo.kiev.ua/information/customer_logs/${currentPage}`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                setCustomerLogs(logResponse.data.rows);
                setSearchingCustomerLogs([]);

                const countResponse = await axios.post(`http://apiwenose.slovo.kiev.ua/count-rows/customer_logs`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                setTotalPages(Math.ceil(countResponse.data / 10));
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearch = async (searchTerm) => {
        if (searchTerm.length === 0) {
            setSearchingCustomerLogs([]);
        } else {
            try {
                const response = await axios.post(`http://apiwenose.slovo.kiev.ua/total-rows/customer_logs`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                const filteredLogs = response.data.filter(log => {
                    return (
                        log.log_id.toString().includes(searchTerm) ||
                        log.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        log.action.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                });
                setSearchingCustomerLogs(filteredLogs);
            } catch (error) {
                console.error("Error during search: ", error);
            }
        }
    };

    return (
        <div className='mlp'>
            <div className="users-container">
                <table>
                    <caption>
                        <SearchInput onSearch={handleSearch} />
                    </caption>
                    <tbody>
                        <tr>
                            <th>log id</th>
                            <th>User id</th>
                            <th>User name</th>
                            <th>Action</th>
                            <th>Action date</th>
                        </tr>

                        <Suspense fallback={<tr><td colSpan="5"><Spinner /></td></tr>}>
                            <RenderInformationForLogs 
                                ListOfRows={searchingCustomerLogs.length > 0 ? searchingCustomerLogs : customerLogs} 
                            />
                        </Suspense>
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => handlePageChange(currentPage - 10)} disabled={currentPage < 11}>
                    <MdKeyboardDoubleArrowLeft />
                </button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                    Next
                </button>
                <button onClick={() => handlePageChange(currentPage + 10)} disabled={currentPage + 10 > totalPages}>
                    <MdKeyboardDoubleArrowRight />
                </button>
            </div>
        </div>
    );
}

export default CustomerLogs;
