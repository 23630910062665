import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";
import SearchInput from "../adminPanelComponents/SearchInput";
import Cookies from "js-cookie";

import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

import Spinner from "../components/Spinner";

const RenderInformationForPromocoodes = lazy(() => import('../adminPanelComponents/RenderInformationForPromocoodes'));

function Promocodes({ userRole }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [promocodes, setPromocodes] = useState([]);
    const [searchingPromocodes, setSearchingPromocodes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const promoResponse = await axios.post(`http://apiwenose.slovo.kiev.ua/information/promocodes/${currentPage}`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                setPromocodes(promoResponse.data.rows);
                setSearchingPromocodes([]);

                const countResponse = await axios.post(`http://apiwenose.slovo.kiev.ua/count-rows/promocodes`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                setTotalPages(Math.ceil(countResponse.data / 10));
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearch = async (searchTerm) => {
        if (searchTerm.length === 0) {
            setSearchingPromocodes([]);
        } else {
            try {
                const response = await axios.post(`http://apiwenose.slovo.kiev.ua/total-rows/promocodes`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('Access_token')}`,
                        'Refresh-Token': Cookies.get('Refresh_token')
                    }
                });
                const filteredPromocodes = response.data.filter(user => {
                    return (
                        user.PromocodeID.toString().includes(searchTerm) ||
                        user.PromocodeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        user.Owner.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                });
                setSearchingPromocodes(filteredPromocodes);
            } catch (error) {
                console.error("Error during search: ", error);
            }
        }
    };

    return (
        <div className='mlp'>
            <div className="users-container">
                <table>
                    <caption>
                        <SearchInput onSearch={handleSearch} />
                    </caption>
                    <tbody>
                        <tr>
                            <th>PromocodeID</th>
                            <th>PromocodeName</th>
                            <th>Discount</th>
                            <th>Owner</th>
                            <th>CreateDate</th>
                            <th>Actiivates</th>
                            {userRole === "Admin" ? <th>Actions</th> : null}
                        </tr>

                        <Suspense fallback={<tr><td colSpan="7"><Spinner /></td></tr>}>
                            <RenderInformationForPromocoodes 
                                ListOfRows={searchingPromocodes.length > 0 ? searchingPromocodes : promocodes} 
                                userRole={userRole} 
                            />
                        </Suspense>
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => handlePageChange(currentPage - 10)} disabled={currentPage < 11}>
                    <MdKeyboardDoubleArrowLeft />
                </button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
                    Next
                </button>
                <button onClick={() => handlePageChange(currentPage + 10)} disabled={currentPage + 10 > totalPages}>
                    <MdKeyboardDoubleArrowRight />
                </button>
            </div>
        </div>
    );
}

export default Promocodes;
